<template>
  <AppFormInput
    v-bind="$attrs"
    v-model="innerValue"
    v-mask="cardMaskPattern"
    :placeholder="placeholder"
    :prepend-inner-icon="icon"
    :bs-type-label="bsTypeLabel"
  >
    <template v-slot:append-outer>
      <v-img
        v-if="logo"
        :src="logo"
        height="60px"
        width="60px"
        contain
        style="margin-top: -20px"
      />
    </template>
  </AppFormInput>
</template>

<script>
import { formInputMixins } from '@/mixins/formInputMixins'
import {creditCardMixins} from '@/mixins/creditCardMixins'

export default {
  name: 'AppCreditCardInput',
  mixins: [formInputMixins, creditCardMixins],
  computed: {
    cardNumber() {
      return this.innerValue || ''
    },
    icon() {
      return 'icon-card'
    },
    placeholder() {
      return this.cardMaskPattern.replaceAll('#', '0')
    }
  }
}
</script>

<style scoped>

</style>
